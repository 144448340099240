import { arrayRange } from './helpers';

const BOOKS_FOR_PAGE = 20;
const POPULAR_BOOKS = 10;
const SEARCH_BOOKS = 10;
const PRERENDER_PAGES = 1;
const RUSSIAN_ALPHABET = ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ё', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Щ', 'Э', 'Ю', 'Я'];
const ENGLISH_ALPHABET = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
const DAYS = arrayRange(1, 31, 1);
const MONTHS = [
  { name: 'Январь', value: '01' },
  { name: 'Февраль', value: '02' },
  { name: 'Март', value: '03' },
  { name: 'Апрель', value: '04' },
  { name: 'Май', value: '05' },
  { name: 'Июнь', value: '06' },
  { name: 'Июль', value: '07' },
  { name: 'Август', value: '08' },
  { name: 'Сентябрь', value: '09' },
  { name: 'Октябрь', value: '10' },
  { name: 'Ноябрь', value: '11' },
  { name: 'Декабрь', value: '12' },
];

const MONTH_NAMES = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

const YEARS = arrayRange(1923, 2018, 1);
const GENDER = [
  { name: 'Мужской', value: 'male' },
  { name: 'Женский', value: 'femail' },
];
const SLIDER_LFROM = '?lfrom=1142850154';
const REVALIDATE_TIME = 300;

const partnerBooks = [
  { book: { bookLink: `https://www.litres.ru/mark-amvreliy-19957445/iduschiy-v-teni-45742140${SLIDER_LFROM}`, id: '45742140', title: 'Идущий в тени, Марк Амврелий' } },
  { book: { bookLink: `https://www.litres.ru/andzhey-sapkovskiy/sezon-groz-8648058${SLIDER_LFROM}`, id: '8648058', title: 'Сезон гроз, Анджей Сапковский' } },
  { book: { bookLink: `https://www.litres.ru/andrey-bulychev/dragun-na-kavkaz-69240445${SLIDER_LFROM}`, id: '69240445', title: 'Драгун, на Кавказ, Андрей Булычев' } },
  { book: { bookLink: `https://www.litres.ru/antuan-de-sent-ekzuperi/malenkiy-princ-9815607${SLIDER_LFROM}`, id: '9815607', title: 'Маленький принц, Антуан де Сент-Экзюпери' } },
  { book: { bookLink: `https://www.litres.ru/daniyar-sugralinov/disgardium-12-edinstvo-1-67988213${SLIDER_LFROM}`, id: '67988213', title: 'Дисгардиум 12. Единство-1, Данияр Сугралинов' } },
  { book: { bookLink: `https://www.litres.ru/tamara-petkevich-23613887/zhizn-sapozhok-neparnyy-kniga-pervaya-68988112${SLIDER_LFROM}`, id: '68988112', title: 'Жизнь – сапожок непарный, Тамара Петкевич' } },
  { book: { bookLink: `https://www.litres.ru/ley-mi/yaschik-skinnera-69366832${SLIDER_LFROM}`, id: '69366832', title: 'Ящик Скиннера, Лэй Ми' } },
  { book: { bookLink: `https://www.litres.ru/anna-babich-32422239/vnutrennyaya-opora-v-luboy-situacii-vozvraschaytes-k-69397075${SLIDER_LFROM}`, id: '69397075', title: 'Внутренняя опора, Анна Бабич' } },
  { book: { bookLink: `https://www.litres.ru/ludmila-martova/ot-zvezdy-i-do-vody-69583753${SLIDER_LFROM}`, id: '69583753', title: 'От звезды и до воды, Людмила Мартова' } },
  { book: { bookLink: `https://www.litres.ru/olivi-bleyk/paradoks-atlasa-69497251${SLIDER_LFROM}`, id: '69497251', title: 'Парадокс Атласа, Оливи Блейк' } },
];

const config = {
  headers: {
    Accept: '/',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Authorization',
  },
};

const slickSliderConfig = {
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3500,
  initialSlide: 0,
  variableWidth: true,
  slidesToScroll: 1,
  slidesToShow: 5,
  swipe: false,
  responsive: [
    {
      breakpoint: 1060,
      settings: {
        swipe: true,
        arrows: false,
        touchThreshold: 1000000,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 530,
      settings: {
        swipe: true,
        arrows: false,
        slidesToShow: 1,
        slidesToSwipe: 1,
        touchThreshold: 1000000,
        swipeToSlide: true,
      },
    },
  ],
};

const API_URL = `${process.env.APP_URL}`;

const RECAPTCHA_SITE_KEY = `${process.env.RECAPTCHA_SITE_KEY}`;

const API_URL_CUSTOM_SLIDER_DEFAULT = `${API_URL}satellite/custom-popular-books/`;

type menuLinkObject = {
  name: string;
  link: string;
};

const MENU_LINKS: menuLinkObject[] = [
  { name: 'Жанры', link: '/zhanr/' },
  { name: 'Новинки', link: '/new/books/' },
  { name: 'Аудиокниги', link: '/audioknigi/' },
  // { name: 'Подкасты', link: '/podcasts/' },
  { name: 'Рейтинг книг', link: '/rating/books/' },
  { name: 'Авторы', link: '/avtor/' },
  { name: 'Серии и саги', link: '/series/' },
];

const ABSOLUTE_URL = 'https://kuchaknig.org';

const DEV_ABSOLUTE_URL = '';
// Переменная для разработки, абсолютным url - для картинок/фрагментов текста/аудио
// const DEV_ABSOLUTE_URL = ABSOLUTE_URL;

export {
  BOOKS_FOR_PAGE,
  POPULAR_BOOKS,
  SEARCH_BOOKS,
  RUSSIAN_ALPHABET,
  ENGLISH_ALPHABET,
  DAYS,
  MONTHS,
  YEARS,
  GENDER,
  MONTH_NAMES,
  API_URL,
  PRERENDER_PAGES,
  API_URL_CUSTOM_SLIDER_DEFAULT,
  DEV_ABSOLUTE_URL,
  ABSOLUTE_URL,
  RECAPTCHA_SITE_KEY,
  MENU_LINKS,
  REVALIDATE_TIME,
  partnerBooks,
  config,
  slickSliderConfig,
};
